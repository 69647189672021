const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Index.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/login/sign-in',
    name: 'sign-in',
    component: () => import('../views/login/Login.vue'),
  },
  {
    path: '/login/reset-password',
    name: 'reset-password',
    component: () => import('../views/login/ResetPassword.vue'),
  },
  {
    path: '/login/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/login/ForgotPassword.vue'),
  },
  {
    path: '/home',
    name: 'home',
    // component: () => import('../views/Home.vue'),
    component: () => import('../views/Qrcode.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/branch-finder',
    name: 'branch-finder',
    component: () => import('../views/BranchFinder.vue'),
  },
  {
    path: '/branch-finder/:id',
    name: 'branch-finder-location',
    component: () => import('../views/branch-finder/Location.vue'),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/Account.vue'),
  },
  {
    path: '/account/invoice',
    name: 'invoice',
    component: () => import('../views/account/Invoice.vue'),
  },
  {
    path: '/account/personal-data',
    name: 'personal-data',
    component: () => import('../views/account/PersonalData.vue'),
  },
  {
    path: '/account/email',
    name: 'email',
    component: () => import('../views/account/Email.vue'),
  },
  {
    path: '/account/phone',
    name: 'phone',
    component: () => import('../views/account/Phone.vue'),
  },
  {
    path: '/account/password',
    name: 'password',
    component: () => import('../views/account/Password.vue'),
  },
  {
    path: '/account/reset-password',
    name: 'account-reset-password',
    component: () => import('../views/account/ResetPassword.vue'),
  },
  {
    path: '/account/qrcode',
    name: 'qrcode',
    component: () => import('../views/account/Qrcode.vue'),
  },
  {
    path: '/account/pin',
    name: 'pin',
    component: () => import('../views/account/Pin.vue'),
  },
  {
    path: '/account/payment-methods',
    name: 'payment-methods',
    component: () => import('../views/account/PaymentMethods.vue'),
  },
  {
    path: '/account/addresses',
    name: 'addresses',
    component: () => import('../views/account/Addresses.vue'),
  },
  {
    path: '/account/customer-cards',
    name: 'customer-cards',
    component: () => import('../views/account/CustomerCards.vue'),
  },
  {
    path: '/account/customer-card',
    name: 'customer-card',
    component: () => import('../views/account/CustomerCard.vue'),
  },
  {
    path: '/account/deactivate',
    name: 'deactivate',
    component: () => import('../views/account/Deactivate.vue'),
  },
  {
    path: '/account/logout',
    name: 'logout',
    component: () => import('../views/account/Logout.vue'),
  },
  {
    path: '/qr-code',
    name: 'qr-code',
    component: () => import('../views/Qrcode.vue'),
  },
  {
    path: '/scan',
    name: 'scan',
    component: () => import('../views/Scan.vue'),
  },
  {
    path: '/scan/smartphone',
    name: 'smartphone',
    component: () => import('../views/scan/Smartphone.vue'),
  },
  {
    path: '/scan/terminal',
    name: 'terminal',
    component: () => import('../views/scan/Terminal.vue'),
  },
  {
    path: '/scan/terminal/terminal',
    name: 'scan-terminal',
    component: () => import('../views/scan/scan/Terminal.vue'),
  },
  {
    path: '/scan/terminal/terminal/terminal',
    name: 'scan-scan-terminal',
    component: () => import('../views/scan/scan/scan/Terminal.vue'),
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/Cart.vue'),
  },
  {
    path: '/cart/purchase',
    name: 'purchase',
    component: () => import('../views/cart/Purchase.vue'),
  },
  {
    path: '/cart/purchase-thank-you',
    name: 'purchase-thank-you',
    component: () => import('../views/cart/ThankYou.vue'),
  },
];

export default routes;
