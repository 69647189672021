// Add element-ui locale
import elementLocaleDE from 'element-ui/lib/locale/lang/de';
import elementLocaleEN from 'element-ui/lib/locale/lang/en';
import i18n from '../plugins/vue-i18n';

/* eslint no-underscore-dangle: ["error", { "allow": ["_vm"] }] */
export default {
  /**
   * AUTH
   */
  setAuth({ commit }) {
    commit('SET_AUTH');
  },

  setEToken({ commit }) {
    commit('SET_ETOKEN');
  },

  /**
   * SETTINGS
   */
  async setSettings({ commit }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}settings`, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
        },
      });
      commit('SET_SETTINGS', response.data);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
   * ALIVE
   */
  async setAlive({ commit }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}alive`, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
        },
        timeout: 3000,
      });

      commit('SET_ALIVE', response.data);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
   * USER
   */
  async setUser({ commit }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}user`, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_USER', response.data);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
   * QR CODE
   */
  async setQrcode({ commit }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}user/qr`, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const image = URL.createObjectURL(blob);
      commit('SET_QRCODE', image);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
   * BRANCH FINDER
   */
  async setLocations({ commit }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}boxes`, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_LOCATIONS', response.data);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
   * CART
   */
  async setCart({ commit }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}cart`, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_CART', response.data);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  async addItem({ commit }, EAN) {
    const formData = new FormData();
    formData.append('articleEAN', EAN);

    try {
      const response = await this._vm.$http.post(`${process.env.VUE_APP_BASE_URL}cart/add`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('ADD_ITEM', response.data);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  async updateItem({ commit }, data) {
    if (data) {
      const formData = new FormData();
      formData.append('itemID', data.id);
      formData.append('quantity', data.quantity);

      try {
        const response = await this._vm.$http.post(`${process.env.VUE_APP_BASE_URL}cart/update`, formData, {
          headers: {
            'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        });

        commit('UPDATE_ITEM', response.data);
        return true;
      } catch (e) {
        // localStorage.removeItem('auth');
        return e.status;
      }
    }
    return false;
  },

  async removeItem({ commit }, id) {
    const formData = new FormData();
    formData.append('itemID', id);

    try {
      const response = await this._vm.$http.post(`${process.env.VUE_APP_BASE_URL}cart/delete`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('REMOVE_ITEM', response.data);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  async createOrder({ commit }) {
    this._vm.$http({
      url: `${process.env.VUE_APP_BASE_URL}cart/createOrder`,
      method: 'post',
      headers: {
        'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
        Authorization: `Bearer ${localStorage.getItem('auth')}`,
      },
    })
      .then((response) => {
        commit('SET_ORDER', response.data);
        return true;
      })
      .catch((e) => {
        // localStorage.removeItem('auth');
        const st = e.status;
        return st;
      });

    // const response = await this._vm.$http
    // .post(`${process.env.VUE_APP_BASE_URL}cart/createOrder`, {
    //   headers: {
    //     'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
    //     Authorization: `Bearer ${localStorage.getItem('auth')}`,
    //   },
    // });
    // try {
    //   commit('SET_ORDER', response.data);
    //   return true;
    // } catch (error) {
    //   console.log(error.response.status);
    //   localStorage.removeItem('auth');
    //   return false;
    // }
  },

  async getOrders({ commit }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}orders`, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });
      commit('SET_ORDER', response.data);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  async clearCart({ commit }) {
    const formData = new FormData();

    try {
      const response = await this._vm.$http.post(`${process.env.VUE_APP_BASE_URL}cart/empty`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('CLEAR_CART', response.data);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  setLocationID({ commit }, locationID) {
    commit('SET_LOCATION_ID', locationID);
  },

  /**
   * PAGE TITLE
   */
  addPageTitle({ commit }, title) {
    commit('ADD_PAGE_TITLE', title);
  },

  removePageTitle({ commit }, title) {
    commit('REMOVE_PAGE_TITLE', title);
  },

  /**
  * TRANSLATIONS
  */
  async setI18nData({ commit }, payload) {
    // this._vm.$http.defaults.timeout = 18000;
    // this._vm.$http.defaults.timeoutErrorMessage = 'timeout';

    try {
      const response = await this._vm.$http({
        method: 'get',
        url: `${process.env.VUE_APP_BASE_URL}resources`,
        params: {
          locale: payload,
        },
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
        },
      });

      // update language in localStorage + update store language
      const responseData = response.data;
      const translationsDE = {};
      const translationsEN = {};
      let translationsFinal = {};
      responseData.forEach((row) => {
        // if german
        if (row.localeID === 22) {
          translationsDE[row.code] = row.text;
        }
        // if english
        if (row.localeID === 352) {
          translationsEN[row.code] = row.text;
        }
      });
      // if german
      if (payload === 'de_AT') {
        translationsFinal = {
          ...translationsDE,
          ...elementLocaleDE,
        };
      }
      // if english
      if (payload === 'en_US') {
        translationsFinal = {
          ...translationsEN,
          ...elementLocaleEN,
        };
      }
      localStorage.removeItem(payload);
      localStorage.removeItem('lang');
      localStorage.setItem(payload, JSON.stringify(translationsFinal));
      localStorage.setItem('lang', payload);
      i18n.setLocaleMessage(localStorage.getItem('lang'), translationsFinal);
      commit('SET_I18N', response.data);
      return i18n;
    } catch (e) {
      // network error
      // alert('Serververbindung aktuell nicht verfügbar');
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
   * COUNTRIES
   */
  async setCountries({ commit }) {
    try {
      const response = await this._vm.$http.get(`${process.env.VUE_APP_BASE_URL}countries`, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
        },
      });

      commit('SET_COUNTRIES', response.data);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
  * EAN
  */
  setEan({ commit }, eanStatus) {
    commit('SET_EAN', eanStatus);
  },

  /**
   * GLOBAL LOADING
   */
  setGlobalLoading({ commit }, status) {
    commit('SET_GLOBAL_LOADING', status);
  },
};
