export default {
  settings: (state) => state.settings,
  alive: (state) => state.alive,
  auth: (state) => state.auth,
  eToken: (state) => state.eToken,
  user: (state) => state.user,
  qrcode: (state) => state.qrcode,
  storeCart: (state) => state.storeCart,
  orders: (state) => state.orders,
  locationID: (state) => state.locationID,
  pageTitle: (state) => state.pageTitle,
  locations: (state) => state.locations,
  i18nData: (state) => state.i18nData,
  countries: (state) => state.countries,
  eanActive: (state) => state.eanActive,
  globalLoading: (state) => state.globalLoading,
};
