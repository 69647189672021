export default {
  /**
   * SETTINGS
   */
  settings: null,

  /**
   * ALIVE
   */
  alive: null,

  /**
   * AUTH
   */
  auth: null,
  eToken: null,

  /**
   * USER
   */
  user: null,

  /**
   * QR CODE
   */
  qrcode: null,

  /**
   * BRANCH FINDER
   */
  locations: null,

  /**
   * CART
   */
  storeCart: {},
  orders: {},
  locationID: null,

  /**
   * PAGE TITLE
   */
  pageTitle: null,

  /**
  * TRANSLATIONS
  */
  translations: null,
  i18nData: null,

  /**
   * USER
   */
  countries: null,

  /**
  * EAN
  */
  eanActive: false,

  /**
  * GLOBAL LOADING
  */
  globalLoading: false,
};
