export default {
  /**
   * SETTINGS
   */
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },

  /**
   * ALIVE
   */
  SET_ALIVE(state, alive) {
    state.alive = alive;
  },

  /**
   * AUTH
   */
  SET_AUTH(state, auth) {
    state.auth = auth;
  },

  SET_ETOKEN(state, eToken) {
    state.eToken = eToken;
  },

  /**
   * USER
   */
  SET_USER(state, user) {
    state.user = user;
  },

  /**
   * QR CODE
   */
  SET_QRCODE(state, qrcode) {
    state.qrcode = qrcode;
  },

  /**
   * BRANCH FINDER
   */
  SET_LOCATIONS(state, locations) {
    state.locations = locations;
  },

  /**
   * CART
   */
  SET_CART(state, storeCart) {
    state.storeCart = storeCart;
  },

  ADD_ITEM(state, storeCart) {
    state.storeCart = storeCart;
  },

  UPDATE_ITEM(state, storeCart) {
    state.storeCart = storeCart;
  },

  REMOVE_ITEM(state, storeCart) {
    state.storeCart = storeCart;
  },

  CREATE_ORDER(state, orders) {
    state.orders = orders;
  },

  SET_ORDER(state, orders) {
    state.orders = orders;
  },

  CLEAR_CART(state) {
    state.storeCart.items.forEach((p) => {
      const product = p;
      product.quantity = 1;
    });
    state.storeCart = {};
  },

  SET_LOCATION_ID(state, locationID) {
    state.locationID = locationID;
  },

  /**
   * PAGE TITLE
   */
  ADD_PAGE_TITLE(state, title) {
    state.pageTitle = title;
  },

  REMOVE_PAGE_TITLE(state, title) {
    if (!title) state.pageTitle = null;
  },

  /**
  * TRANSLATIONS
  */
  SET_I18N(state, i18nData) {
    state.i18nData = i18nData;
  },

  /**
  * COUNTRIES
  */
  SET_COUNTRIES(state, countries) {
    state.countries = countries;
  },

  /**
  * EAN
  */
  SET_EAN(state, eanActive) {
    state.eanActive = eanActive;
  },

  /**
   * GLOBAL LOADING
   */
  SET_GLOBAL_LOADING(state, globalLoading) {
    state.globalLoading = globalLoading;
  },
};
