<template>
  <!-- app START -->
  <div id="app">
    <!-- navigation START -->
    <app-navigation/>
    <!-- navigation END -->

    <!-- view START -->
    <div
      :class="[
        'view',
        { 'view-no-margin' : $route.name === 'index' }
      ]"
    >
      <transition
        :name="activeTransition"
        mode="out-in"
      >
        <router-view />
      </transition>
    </div>
    <!-- view END -->

    <!-- global:loading START -->
    <loading-default
      :loadingVisible="$store.getters.globalLoading"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- global:loading END -->

    <!-- footer START -->
    <app-footer/>
    <!-- footer END -->

  </div>
  <!-- app END -->
</template>

<script>
// import store from './store';

export default {
  name: 'App',
  components: {
    AppNavigation: () => import('./components/shared/Navigation.vue'),
    AppFooter: () => import('./components/shared/Footer.vue'),
    LoadingDefault: () => import('./components/shared/loading/Default.vue'),
  },
  data() {
    return {
      /**
       * Loading
       */
      loadingHeadline: this.$t('loading.login.headline'),
      loadingExcerpt: this.$t('loading.login.excerpt'),
      /**
       * Settings
       */
      settings: null,
    };
  },
  async created() {
    this.settings = await this.FETCH_SETTINGS();
    if (this.settings) {
      this.loadScript(`https://maps.googleapis.com/maps/api/js?key=${this.settings.google.maps}&libraries=places,geometry`);
    }
  },
  computed: {
    activeTransition() {
      if (localStorage.getItem('lang') && localStorage.getItem('eToken') && localStorage.getItem('eToken') !== 'undefined') {
        return this.$route.name === 'index' || this.$route.name === 'null' || this.$route.name === 'sign-in' ? 'none' : 'fade';
      }
      return 'fade';
    },
  },
  methods: {
    async FETCH_SETTINGS() {
      await this.$store.dispatch('setSettings');
      return this.$store.getters.settings;
    },

    // loadScript(name) {
    //   return new Promise((resolve) => {
    //     const script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = name;
    //     document.getElementsByTagName('head')[0].appendChild(script);
    //     resolve();
    //   });
    // },

    async loadScript(name) {
      try {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = name;
        document.getElementsByTagName('head')[0].appendChild(script);
        return script;
      } catch (error) {
        return error;
      }
    },
  },
};
</script>
