/* eslint camelcase: ["error", {properties: "never"}] */
import { Device } from '@capacitor/device';
import { App as CapApp } from '@capacitor/app';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
import { Network } from '@capacitor/network';

import Vue from 'vue';
import dayjs from 'dayjs';
import VueI18n from 'vue-i18n';
import ElementUI from 'element-ui';
import App from './App.vue';
import router from './router';
import store from './store';

/**
 * Plugins
 * Import plugins here
 */
import './plugins/vue-axios';
import './plugins/vue-logger';
import './plugins/vue-anime';
import './plugins/dayjs';
import './plugins/vue-swiper';
import './plugins/vue-mask';

/**
 * Main style
 * Import any additional style here
 */
import 'element-ui/lib/theme-chalk/index.css';
import './assets/scss/styles.scss';

Vue.config.productionTip = false;

/**
 * Action sheet
 * SERVER network status
 */
const showServerActions = async () => {
  const result = await ActionSheet.showActions({
    title: 'Serververbindung aktuell nicht verfügbar',
    options: [
      {
        title: 'App schließen',
        style: ActionSheetButtonStyle.Cancel,
      },
    ],
  });

  if (result && result.index === 0) {
    CapApp.exitApp();
  }
};

/**
 * Action sheet
 * USER network status
 */
const showUserActions = async () => {
  const result = await ActionSheet.showActions({
    title: 'Kein Internet',
    options: [
      {
        title: 'App schließen',
        style: ActionSheetButtonStyle.Cancel,
      },
    ],
  });

  if (result && result.index === 0) {
    CapApp.exitApp();
  }
};

/**
 * USER
 * Get current network status
 */
const logCurrentNetworkStatus = async () => {
  const status = await Network.getStatus();

  if (status.connected === false) {
    // show sheet (user)
    showUserActions().then((action) => action);
  } else {
    // check server network status & show sheet (server)
    const serverStatus = await store.dispatch('setAlive');
    if (!serverStatus) {
      showServerActions().then((action) => action);
    }
  }
};
// listener - if user network status changes
// Network.addListener('networkStatusChange', (status) => {
//   console.log(status);
//   if (!status.connected) {
//     // check server and/or user network error
//     logCurrentNetworkStatus();
//   }
// });

/**
 * Router
 * Navigation Guards - Network status
 */
router.afterEach(async () => {
  // check server and/or user network error
  logCurrentNetworkStatus();
});

/**
 * Router
 * Navigation Guards - Disable Global Loading
 */
router.afterEach(() => {
  // show loading
  store.dispatch('setGlobalLoading', false);
});

router.beforeEach((to, from, next) => {
  // scroll to top of view
  window.scrollTo(0, 0);
  /**
   * Router
   * Navigation Guards - Check session timeout
   */
  if (to.path !== '/' && to.path !== '/register' && to.path !== '/login' && to.path !== '/login/sign-in' && to.path !== '/login/reset-password' && to.path !== '/login/forgot-password') {
    let { timeout } = localStorage;
    timeout = dayjs(timeout).format();
    const now = dayjs();
    // if ok -> update timeout, else logout / redirect to sign in
    if (now.isBefore(dayjs(timeout))) {
      localStorage.setItem('timeout', now.add(1, 'hour'));
      next();
    } else {
      next('/login/sign-in');
    }
  } else {
    next();
  }
  /**
   * Navigation Guard - Welcome and/or Login Cookies + Set Global Loading
   */
  if (to.path === '/') {
    // show loading -> then redirect -> to show loading bar on the index view, too
    if (localStorage.getItem('lang') && localStorage.getItem('eToken') && localStorage.getItem('eToken') !== 'undefined') {
      // show loading -> then redirect
      store.commit('SET_GLOBAL_LOADING', true);
      next('/login/sign-in');
    } else if (localStorage.getItem('welcomeScreen')) {
      // show loading -> then redirect
      store.commit('SET_GLOBAL_LOADING', true);
      next('/login');
    }
  }
});

/**
 * Language
 * get device language
 */
const logDeviceInfo = async () => {
  const deviceLanguage = await Device.getLanguageCode();
  return deviceLanguage;
};

/**
 * Language
 * set device language
 */
logDeviceInfo().then((device) => {
  let deviceLanguage = device.value;
  deviceLanguage = deviceLanguage.replace(/-/g, '_');

  // set default language
  if (deviceLanguage === 'de') {
    deviceLanguage = 'de_AT';
  } else if (deviceLanguage === 'en') {
    deviceLanguage = 'en_US';
  } else {
    deviceLanguage = 'de_AT';
  }
  localStorage.setItem('lang', deviceLanguage);

  // init load language
  store.dispatch('setI18nData', deviceLanguage).then((response) => {
    if (response) {
      localStorage.removeItem(deviceLanguage);
      localStorage.removeItem('lang');
      localStorage.setItem(deviceLanguage, response.messages[`${deviceLanguage}`]);
      localStorage.setItem('lang', deviceLanguage);

      const i18n = new VueI18n({
        locale: deviceLanguage,
        fallbackLocale: deviceLanguage,
      });

      // set default messages
      i18n.setLocaleMessage(deviceLanguage, response.messages[`${deviceLanguage}`]);

      // update element UI component languages
      Vue.use(ElementUI, {
        i18n: (key, value) => i18n.t(key, value),
      });

      // init app
      new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App),
      }).$mount('#app');
    } else {
      // check server and/or user network error
      logCurrentNetworkStatus();
    }
  });
});
